/**
 * An enumeration of reasons why we might send an applicant to a notary thanks page.
 */
export enum MiscThanksReasons {
    identity = 'identity',
    noCameraAccess = 'noCameraAccess',
    authorization = 'authorization',
    review = 'review',
    notaryRejection = 'notaryRejection',
    lien = 'lien',
    privateBrowsing = 'privateBrowsing',
    default = 'default',
    unexpectedErrorOnNotarizationPage = 'unexpectedErrorOnNotarizationPage',
}

/**
 * Reasons around notary appointment scheduling that might cause an applicant to be directed to a Thanks page.
 * These are distinct from MiscThanksReasons because they (generally) send the applicant to ThanksReschedule.vue
 * rather than Thanks.vue, though that isn't strictly true.
 */
export enum SchedulingThanksReasons {
    tooEarlyToAppointment = 'tooEarlyToAppointment',
    priorCompletedAppointment = 'priorCompletedAppointment',
    ineligibleAppointment = 'ineligibleAppointment',
    missedAppointment = 'missedAppointment',
    rescheduling = 'rescheduling',
    applicantNotReadyForSession = 'applicantNotReadyForSession',
}
