import { RouteConfig } from 'vue-router'

const routeName = 'retention'

const pageNames = {
    WELCOME: 'welcome',
}

export const cliAndAprReductionAndBalanceSweepPagePaths = {
    WELCOME: `/${routeName}/${pageNames.WELCOME}`,
}

const completionRoutes: RouteConfig[] = [
    {
        path: cliAndAprReductionAndBalanceSweepPagePaths.WELCOME,
        component: () => import('@/pages/churnRetention/Welcome.vue'),
        meta: { transitionName: 'slide' },
    },
]

export const cliAndAprReductionAndBalanceSweepRoutes: RouteConfig[] = [...completionRoutes]
