import { RouteConfig } from 'vue-router'

const routeName = 'notary'

const pageNames = {
    NOTARY_LOGIN: 'login',
    NOTARY_SESSION: 'session',
    NOTARY_PARTICIPANT: 'notary_participant',
}

export const notaryPagePaths = {
    NOTARY_LOGIN: `/${routeName}/${pageNames.NOTARY_LOGIN}`,
    NOTARY_SESSION: `/${routeName}/${pageNames.NOTARY_SESSION}`,
    NOTARY_PARTICIPANT: `/${routeName}/${pageNames.NOTARY_PARTICIPANT}`,
}

export const notaryRoutes: RouteConfig[] = [
    {
        path: notaryPagePaths.NOTARY_SESSION,
        component: () => import('@/pages/notary/NotarySession.vue'),
        meta: {
            transitionName: 'slide',
            public: true,
        },
    },
    {
        path: notaryPagePaths.NOTARY_LOGIN,
        component: () => import('@/pages/notary/Login.vue'),
        meta: {
            transitionName: 'slide',
            public: true,
        },
    },
    {
        path: notaryPagePaths.NOTARY_PARTICIPANT,
        component: () => import('@/pages/notary/VisitorNotarySession.vue'),
        meta: {
            transitionName: 'slide',
            public: true,
        },
    },
]
