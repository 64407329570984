import { RouteConfig } from 'vue-router'

const routeName: string = 'auth'

const pageNames = {
    RETURN_TO_NOTARIZATION: 'notarization', // be cautious changing this route, backend logic generates this link
    SECONDARY_USER_PERSONAL_INFO: 'coowner', // be cautious changing this route, backend logic generates this link
}

export const authPagePaths = {
    ROOT: '/',
    // https://stackoverflow.com/a/47828416/858775
    RETURN_TO_NOTARIZATION: `/${routeName}/${pageNames.RETURN_TO_NOTARIZATION}`,
    RETURN_TO_NOTARIZATION_PATH_PARAMETERIZED: `/${routeName}/${pageNames.RETURN_TO_NOTARIZATION}/:p`,
    SECONDARY_USER_PERSONAL_INFO: `/${routeName}/${pageNames.SECONDARY_USER_PERSONAL_INFO}`,
    SECONDARY_USER_PERSONAL_INFO_PATH_PARAMETERIZED: `/${routeName}/${pageNames.SECONDARY_USER_PERSONAL_INFO}/:p`,
}

export const authRoutes: RouteConfig[] = [
    {
        path: authPagePaths.ROOT,
        redirect: authPagePaths.RETURN_TO_NOTARIZATION,
    },
    {
        path: authPagePaths.RETURN_TO_NOTARIZATION,
        component: () => import('@/pages/auth/ReturnToNotarization.vue'),
        meta: { public: true },
    },
    {
        path: authPagePaths.RETURN_TO_NOTARIZATION_PATH_PARAMETERIZED,
        redirect: (to) => {
            return { path: authPagePaths.RETURN_TO_NOTARIZATION, query: { p: to.params.p, isPrimary: to?.query?.isPrimary } }
        },
    },
    {
        path: authPagePaths.SECONDARY_USER_PERSONAL_INFO,
        component: () => import('@/pages/auth/SecondaryUserPersonalInfo.vue'),
        meta: { public: true },
    },
    {
        path: authPagePaths.SECONDARY_USER_PERSONAL_INFO_PATH_PARAMETERIZED,
        redirect: (to) => {
            return { path: authPagePaths.SECONDARY_USER_PERSONAL_INFO, query: { p: to.params.p } }
        },
    },
]
