import 'url-search-params-polyfill'
import { appSessionStorage, sessionStorageKey } from '@/utils/storage'
import { logger } from '@/utils/logger'
import { httpClient } from '@/utils/http-client'
import { fireAndForget } from '@/utils/fireAndForget'
import { openReplay } from './openreplay'

export const setLocalMetadata = () => {
    if (document.referrer) {
        const parser = new URL(document.referrer)
        window.logEvent('system_aven_init', { referrer: document.referrer, referring_domain: parser.hostname })
    }
}

export const submitSessionRecordingUrl = async (sessionId: string | null, sessionRecordingUrl: string) => {
    appSessionStorage.setItem(sessionStorageKey.sessionRecordingInitialized, 'true')
    appSessionStorage.setItem(sessionStorageKey.sessionRecordingUrl, sessionRecordingUrl)
    try {
        await httpClient.post('/ana/sessionRecordingUrl', { sessionId, sessionRecordingUrl })
    } catch (error) {
        logger.fatal(`submission failed, sessionRecordingUrl, ${sessionRecordingUrl}`, error)
    }
}

export const getUserTraits = (): Record<string, any> => {
    const traits: Record<string, any> = {}
    const loanApplicationId = appSessionStorage.getItem(sessionStorageKey.loanApplicationId)
    const applicantId = appSessionStorage.getItem(sessionStorageKey.applicantId)
    const applicantNotaryAssignmentId = appSessionStorage.getItem(sessionStorageKey.applicantNotaryAssignmentId)
    const abTests = appSessionStorage.getItem(sessionStorageKey.experimentOverrides)

    if (loanApplicationId) {
        traits.loanApplicationId = loanApplicationId
    }

    if (applicantId) {
        traits.applicantId = applicantId
    }

    if (applicantNotaryAssignmentId) {
        traits.applicantNotaryAssignmentId = applicantNotaryAssignmentId
    }

    if (abTests) {
        traits.abTests = abTests
    }

    return traits
}

export const initializeSessionRecording = () => {
    const sessionId = appSessionStorage.getItem(sessionStorageKey.sessionId)
    if (sessionId) {
        const userTraits = getUserTraits()
        logger.info(`init openReplay session with traits: ${JSON.stringify(userTraits)}`)
        fireAndForget(
            () => openReplay.init(sessionId, userTraits),
            (e) => logger.fatal(`Failed to initialize openreplay!`, e)
        )
    } else {
        logger.fatal(`Could not initialize session recording with no sessionId!`)
    }
}

export const setSessionMetadata = (metadata: Record<string, any>) => {
    openReplay.trySetMetadata(metadata)
}
