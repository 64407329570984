import { authPagePaths } from '@/routes/authRoutes'
import { originationPagePaths } from '@/routes/originationRoutes'
import { checkAreAllPathsUnique, checkPathsMatch, RouteOption, tryGetNextFlowPath, tryGetOptionalPath } from '@/flow/flowUtility'
import { cliAndAprReductionAndBalanceSweepPagePaths } from '@/routes/cliAndAprReductionAndBalanceSweepRoutes'
import { sharedPagePaths } from '@/routes/sharedRoutes'

export const cliAndAprReductionAndBalanceSweepEntryPagePaths: string[] = [authPagePaths.RETURN_TO_NOTARIZATION, authPagePaths.SECONDARY_USER_PERSONAL_INFO]

export const cliAndAprReductionAndBalanceSweepReturnPagePathPairs: string[][] = [
    [authPagePaths.RETURN_TO_NOTARIZATION, originationPagePaths.NOTARY_SCHEDULING],
    [authPagePaths.SECONDARY_USER_PERSONAL_INFO, originationPagePaths.NOTARY_SCHEDULING],
]

export const cliAndAprReductionAndBalanceSweepBackGuardPagePaths: string[] = [
    sharedPagePaths.NOTARY_REQUIRES_SAFARI,
    originationPagePaths.IDENTITY_QUESTIONS,
    originationPagePaths.REMOTE_NOTARIZATION_SESSION,
    sharedPagePaths.NOTARY_REQUIRES_AV,
    cliAndAprReductionAndBalanceSweepPagePaths.WELCOME,
]

const cliAndAprReductionAndBalanceSweepFlow: string[] = [
    originationPagePaths.NOTARY_SCHEDULING,
    originationPagePaths.SCHEDULING_CONFIRMATION,
    originationPagePaths.NOTARY_KBA_START,
    originationPagePaths.NOTARY_AV_PERMISSIONS,
    originationPagePaths.REMOTE_NOTARIZATION_SESSION,
    cliAndAprReductionAndBalanceSweepPagePaths.WELCOME,
]

const returnToNotarizationFlowGroup: Map<string, string[]> = new Map([[RouteOption.goToWelcome, [cliAndAprReductionAndBalanceSweepPagePaths.WELCOME]]])

const experianFrozenFlow: string[] = [originationPagePaths.EXPERIAN_FROZEN]

const schedulingFlowGroup: Map<string, string[]> = new Map([[RouteOption.experianFrozen, experianFrozenFlow]])

const identityQuestionsFlow: string[] = [originationPagePaths.IDENTITY_QUESTIONS]
const identityFlowGroup: Map<string, string[]> = new Map([[RouteOption.identityQuestions, identityQuestionsFlow]])

const kbaQuestionsFlow: string[] = [originationPagePaths.NOTARY_KBA_QUESTIONS]
const kbaFlowGroup: Map<string, string[]> = new Map([[RouteOption.identityQuestions, kbaQuestionsFlow]])

const optionalFlowMap: Map<string, Map<string, string[]>> = new Map([
    [originationPagePaths.VERIFY_IDENTITY, identityFlowGroup],
    [originationPagePaths.NOTARY_KBA_START, kbaFlowGroup],
    [originationPagePaths.NOTARY_SCHEDULING, schedulingFlowGroup],
    [authPagePaths.RETURN_TO_NOTARIZATION, returnToNotarizationFlowGroup],
])

// Could be a unit test
if (!checkAreAllPathsUnique(cliAndAprReductionAndBalanceSweepFlow, optionalFlowMap)) {
    throw new Error('credit limit increase page paths not unique!')
}

export const getCliAndAprReductionAndBalanceSweepNextRouteWithCurrentPath = (currentPath: string, routeOption?: RouteOption): string | null => {
    if (routeOption) {
        return tryGetOptionalPath(currentPath, optionalFlowMap, routeOption)
    }

    if (cliAndAprReductionAndBalanceSweepEntryPagePaths.findIndex((path) => checkPathsMatch(path, currentPath)) >= 0) {
        return cliAndAprReductionAndBalanceSweepFlow[0]
    }

    for (const [path, nextPath] of cliAndAprReductionAndBalanceSweepReturnPagePathPairs) {
        if (checkPathsMatch(path, currentPath)) {
            return nextPath
        }
    }

    return tryGetNextFlowPath(currentPath, cliAndAprReductionAndBalanceSweepFlow, optionalFlowMap)
}
