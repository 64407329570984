import { RouteConfig } from 'vue-router'

const routeName = 'shared'

const pageNames = {
    THANKS: 'thanks',
    UNABLE_TO_VERIFY_IDENTITY: 'unable_to_verify_identity',
    NOTARY_REQUIRES_SAFARI: 'notary_requires_safari',
    NOTARY_REQUIRES_CHROME: 'notary_requires_chrome',
    NOTARY_REQUIRES_AV: 'notary_requires_av',
}

export const sharedPagePaths = {
    THANKS: `/${routeName}/${pageNames.THANKS}`,
    UNABLE_TO_VERIFY_IDENTITY: `/${routeName}/${pageNames.UNABLE_TO_VERIFY_IDENTITY}`,
    NOTARY_REQUIRES_SAFARI: `/${routeName}/${pageNames.NOTARY_REQUIRES_SAFARI}`,
    NOTARY_REQUIRES_CHROME: `/${routeName}/${pageNames.NOTARY_REQUIRES_CHROME}`,
    NOTARY_REQUIRES_AV: `/${routeName}/${pageNames.NOTARY_REQUIRES_AV}`,
    PAGE_NOT_FOUND: `*`,
}

export const sharedRoutes: RouteConfig[] = [
    {
        path: sharedPagePaths.PAGE_NOT_FOUND,
        component: () => import('@/pages/auth/ReturnToNotarization.vue'),
        meta: { public: true },
    },
    {
        path: sharedPagePaths.THANKS,
        component: () => import('@/pages/shared/Thanks.vue'),
        meta: { public: true },
    },
    {
        path: sharedPagePaths.NOTARY_REQUIRES_SAFARI,
        component: () => import('@/pages/shared/NotaryRequiresSafari.vue'),
        meta: { public: true },
    },
    {
        path: sharedPagePaths.NOTARY_REQUIRES_CHROME,
        component: () => import('@/pages/shared/NotaryRequiresChrome.vue'),
        meta: { public: true },
    },
    {
        path: sharedPagePaths.NOTARY_REQUIRES_AV,
        component: () => import('@/pages/shared/NotaryRequiresAV.vue'),
        meta: { public: true },
    },
    {
        path: sharedPagePaths.UNABLE_TO_VERIFY_IDENTITY,
        component: () => import('@/pages/shared/UnableToVerifyIdentity.vue'),
        meta: { public: true },
    },
]
