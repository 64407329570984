import { extend, configure } from 'vee-validate'
import startCase from 'lodash/startCase'
import { required, email, min, max, numeric, length, min_value, is_not } from 'vee-validate/dist/rules'
import { i18n } from './i18n'
import { logger } from '@/utils/logger'
import { us_states } from '@/utils/us-states-dictionary'
import { calculateAge } from '@/utils/date'
import { last } from 'lodash'
import tldEnum from 'tld-enum'
import formatMixin from '@/mixins/format'

configure({
    defaultMessage: (field, values) => {
        return i18n.t(`validation.${values._rule_}`, values)
    },
})

// Install required rule and message.
extend('required', required)

// Install length rule and message.
extend('length', length)

// Install is_not rule and message.
extend('is_not', is_not)

// Install email rule and message.
extend('email', {
    ...email,
    validate: (value) => {
        const tld = last(value?.toLowerCase()?.split('.'))
        if (!tldEnum.list.includes(tld)) {
            return false
        }

        return email.validate(value, {
            require_tld: true,
            allow_utf8_local_part: false,
            domain_specific_validation: true,
        })
    },
})
// Install min rule and message.
extend('min', min)

// Install min rule and message.
extend('min_value', min_value)

// Install max rule and message.
extend('max', max)

// Install numeric rule and message.
extend('numeric', numeric)

// Install full name (2 word/space in between minimum) rule
extend('fullname', {
    validate: (value) => {
        if (!/\w+\s\w+/.test(value)) {
            return i18n.t('validation.fullname')
        }
        return true
    },
})

extend('signatureValidator', {
    params: ['suggestedSignature'],
    message: (field, params) => {
        return `Signature must match ${params.suggestedSignature}`
    },
    validate: (value, { suggestedSignature }) => {
        return value.toLowerCase().trim() === suggestedSignature.toLowerCase().trim()
    },
})

// date validation rule
extend('addressRule', {
    // eslint-disable-next-line no-unused-vars
    validate: (value) => {
        const usAddress = value.toLowerCase().trimEnd(', usa')
        const addressComponents = usAddress.split(',')
        const addressStreet = addressComponents[0]
        const addressCity = addressComponents[1]
        const stateZip = addressComponents[2].trim().split(' ')
        const addressState = stateZip[0]
        const addressPostalCode = stateZip[1]

        if (!/^\d{5}/.test(addressPostalCode)) {
            logger.info('addressRule: invalid zip code')
            return i18n.t('validation.invalidPostalCode')
        }

        if (!us_states[addressState.toUpperCase()]) {
            logger.info('addressRule: invalid state')
            return i18n.t('validation.invalidState')
        }

        if (!/^\d+(\s[a-zA-Z0-9]+)+/.test(addressStreet)) {
            logger.info('addressRule: street regex failed')
            return i18n.t('validation.invalidStreetAddress')
        }

        if (!addressCity) {
            logger.info('addressRule: invalid city')
            return i18n.t('validation.invalidCity')
        }
        return true
    },
})

extend('secondaryAddressUnitRule', {
    // eslint-disable-next-line no-unused-vars
    validate: (value) => {
        if (!value) {
            return true
        }
        logger.info(`apt input ${value}`)
        const parts = value.trim().split(/[ ]+/)
        logger.info(`apt input parts: ${parts}`)

        if (parts.length > 2 || parts.length === 0) {
            return i18n.t('validation.malformedSecondaryAddress')
        }
        const unitNumber = parts.pop()
        logger.info(`unit number candidate: ${unitNumber}`)
        /*
            \d{1,6} matches a digit (equal to [0-9])
            {1,6} Quantifier — Matches between 1 and 6 times, as many times as possible, giving back as needed (greedy)
            \w{0,1} matches any word character (equal to [a-zA-Z0-9_])
            {0,1} Quantifier — Matches between zero and one times, as many times as possible, giving back as needed (greedy)
            Negative Lookahead (?!.)
            Assert that the Regex below does not match
            . matches any character (except for line terminators)
         */
        if (/\d{1,6}\w?(?!.)/.test(unitNumber) && unitNumber.length < 6) {
            logger.info(`${unitNumber} match`)
            return true
        } else {
            logger.info(`${unitNumber} not match \\d{1,6}\\w?(?!.)`)
            return i18n.t('validation.malformedSecondaryAddress')
        }
    },
})

// date validation rule
extend('dateRule', {
    // eslint-disable-next-line no-unused-vars
    validate: (value) => {
        if (/(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d/.test(value)) {
            return true
        } else {
            return i18n.t('validation.invalidDate')
        }
    },
})

// date validation rule
extend('phoneNumberRule', {
    // eslint-disable-next-line no-unused-vars
    validate: (value) => {
        // Replace any extra chars (i.e. () / . / - )
        value = value?.replace(/[-.() ]/gi, '')

        logger.info(`phoneNumberRule validation on: ${value}`)

        if (value.length === 12 && value.startsWith('+1')) {
            return true
        }
        if (value.length === 11 && value.startsWith('1')) {
            return true
        }
        if (value.length === 10 && !(value.startsWith('1') || value.startsWith('+'))) {
            return true
        }

        return i18n.t('customValidationRules.notValidPhoneNumber')
    },
})

extend('californiaResidencyRule', {
    // eslint-disable-next-line no-unused-vars
    validate: (value) => {
        logger.info(`californiaResidencyRule validation on: ${value}`)
        const isCalifornia = value.toLowerCase() === 'california'
        if (isCalifornia) {
            return true
        }
        const indexOfCA = value.indexOf('CA')
        if (indexOfCA < 0) {
            return i18n.t('customValidationRules.CAResidentOnly')
        }
        return true
    },
})

extend('ageRule', {
    // eslint-disable-next-line no-unused-vars
    validate: (value) => {
        logger.info(`calculate age: ${value}`)
        const applicantAge = calculateAge(value)
        if (applicantAge < 18) {
            return i18n.t('customValidationRules.underAge')
        }
        return true
    },
})

extend('addressAutoCompleteRule', {
    // eslint-disable-next-line no-unused-vars
    validate: (value) => {
        logger.info(`addressAutoCompleteRule validation on: ${value}`)
        const addressComponents = value.split(',')
        if (addressComponents.length < 3) {
            return i18n.t('customValidationRules.incompleteAddress')
        }
        const addressStreet = startCase(addressComponents[0].toLowerCase())
        const addressCity = startCase(addressComponents[1].toLowerCase())
        const addressState = addressComponents[2].toUpperCase()
        const country = addressComponents[3]

        if (addressStreet && addressCity && addressState && country) {
            return true
        }
        return i18n.t('customValidationRules.incompleteAddress')
    },
})

extend('twilioCode', {
    validate: (value) => {
        if (value.length != 4) {
            return i18n.t('customValidationRules.twilioCode')
        }
        return true
    },
})

extend('currency', {
    validate: (value, { min, max }) => {
        const minAmount = min !== undefined ? min : -Infinity
        const maxAmount = max !== undefined ? max : Infinity
        if (isNaN(parseFloat(value))) {
            return i18n.t('customValidationRules.invalidCurrency')
        } else if (parseFloat(value) < minAmount) {
            return i18n.t('customValidationRules.currencyTooSmall', { minAmount: formatMixin.methods.toFormattedUSDNoCents(minAmount) })
        } else if (parseFloat(value) > maxAmount) {
            return i18n.t('customValidationRules.currencyTooLarge', { maxAmount: formatMixin.methods.toFormattedUSDNoCents(maxAmount) })
        }
        return true
    },
    params: ['min', 'max'],
})
