import { logger } from '@/utils/logger'

export default {
    methods: {
        toFormattedUSDStripTrailingZeroCents(number) {
            let stringNum = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
            }).format(number)

            stringNum = stringNum.replace(/\.00$/, '')

            return stringNum
        },
        toFormattedUSDNoCents(number) {
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                maximumSignificantDigits: 3,
            }).format(number)
        },
        formatApr(value) {
            return `${parseFloat(String(value * 100))
                .toFixed(2)
                .replace('.00', '')}%`
        },
        toFormattedAprStripTrailingZerosAfterDecimalPoint(value) {
            let stringNum = parseFloat(String(value * 100)).toFixed(2)

            stringNum = stringNum.replace(/\.00$/, '')

            if (/\.\d0$/.test(stringNum)) {
                // match XX.X0 to drop the last 0
                return stringNum.slice(0, stringNum.length - 1) + '%'
            }
            return stringNum + '%'
        },
        // use lodash?
        capitalize: (value) => {
            if (!value) return ''

            const ret = value.charAt(0).toUpperCase() + value.substring(1).toLowerCase()
            logger.info(`value: ${value} camcelCase: ${ret}`)
            return ret
        },
    },
}
