import { RouteConfig } from 'vue-router'

const routeName = 'waitroom'

const pageNames = {
    HOST: 'host',
    JOIN: 'join',
    PARTICIPANT: 'p',
}

export const conferenceRoomPagePaths = {
    HOST: `/${routeName}/${pageNames.HOST}/:hostGuid`,
    JOIN: `/${routeName}/${pageNames.JOIN}/:jodl`,
    PARTICIPANT: `/${routeName}/${pageNames.PARTICIPANT}/:participantGuid`,
}

export const conferenceRoomRoutes: RouteConfig[] = [
    {
        path: conferenceRoomPagePaths.HOST,
        component: () => import('@/pages/conference/ConferenceHost.vue'),
        meta: { transitionName: 'slide', public: true },
        props: true,
    },
    {
        path: conferenceRoomPagePaths.JOIN,
        component: () => import('@/pages/conference/NewConferenceParticipant.vue'),
        meta: { transitionName: 'slide', public: true },
        props: true,
    },
    {
        path: conferenceRoomPagePaths.PARTICIPANT,
        component: () => import('@/pages/conference/ConferenceRoom.vue'),
        meta: { transitionName: 'slide', public: true },
        props: true,
    },
]
