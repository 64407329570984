<template>
    <div id="app">
        <router-view :key="$route.path" />
    </div>
</template>

<script>
    import Vue from 'vue'
    import { i18n } from './utils/i18n'
    import { setLocalMetadata } from './services/marketing'

    export default Vue.extend({
        name: 'App',
        created() {
            // Set Default Cookie Settings
            Vue.$cookies.config('30d', '', `${process.env.VUE_APP_COOKIES_URL}`, true, 'Strict')

            // Set Locale for Language
            let locale = 'en'
            i18n.locale = locale
            this.$cookies.set('locale', locale)
        },
        mounted() {
            setLocalMetadata()
        },
    })
</script>

<style lang="scss">
    @import 'node_modules/aven_shared/src/styles/main.scss';
    @import '@/styles/tailwind.scss';
</style>
