import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import advanced from 'dayjs/plugin/advancedFormat'

const DISPLAY_DATETIME_FORMAT = 'YYYY-MM-DDThh:mm:ss.SSSZ'

export function calculateAge(dob) {
    const today = new Date()
    const birthDate = new Date(dob)
    let age = today.getFullYear() - birthDate.getFullYear()
    const m = today.getMonth() - birthDate.getMonth()
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age = age - 1
    }

    return age
}

// Taken from aven-my
export function convertDateTimeFormat(inputDateTime: string | Date, inputTimezone: string, outputTimezone: string, outputFormat: string = DISPLAY_DATETIME_FORMAT): string {
    dayjs.extend(utc)
    dayjs.extend(timezone)
    dayjs.extend(advanced)
    if (inputDateTime === null) {
        return null
    }

    return dayjs.tz(inputDateTime, inputTimezone).tz(outputTimezone).format(outputFormat)
}
