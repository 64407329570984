import { notaryPagePaths } from '@/routes/notaryRoutes'
import { checkAreAllPathsUnique, checkPathsMatch, RouteOption, tryGetNextFlowPath, tryGetOptionalPath } from '@/flow/flowUtility'

export const notaryEntryPagePaths = [notaryPagePaths.NOTARY_LOGIN]

const notaryFlow: string[] = [notaryPagePaths.NOTARY_SESSION]

const optionalFlowMap: Map<string, Map<string, string[]>> = new Map([])

// Could be a unit test
if (!checkAreAllPathsUnique(notaryFlow, optionalFlowMap)) {
    throw new Error('notary page paths not unique!')
}

export const getNotaryNextRouteWithCurrentPath = (currentPath: string, routeOption?: RouteOption): string | null => {
    if (routeOption) {
        return tryGetOptionalPath(currentPath, optionalFlowMap, routeOption)
    }

    if (notaryEntryPagePaths.findIndex((path) => checkPathsMatch(path, currentPath)) >= 0) {
        return notaryFlow[0]
    }

    return tryGetNextFlowPath(currentPath, notaryFlow, optionalFlowMap)
}
