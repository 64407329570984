// General try/catch around the whole function to allow this method to safely be called
// asynchronously without crashing the server
// Use this to 'fire and forget' functions when you don't care about the return value
export const fireAndForget = <T>(tryFunc: () => Promise<T>, exceptFunc: (e: Error) => Promise<void> | void): void => {
    void (async (): Promise<void> => {
        try {
            await tryFunc()
        } catch (e) {
            // await on non-promise functions does not do anything
            // i.e. it's OK to await non-promise funcs
            await exceptFunc(e)
        }
    })()
}
